import Link from "next/link";
import { shortDate } from "../pages/blog/[...slug]";

/* eslint-disable @next/next/no-img-element */
export default function BlogPostPreview(props) {
    return (
        <Link href={props.url}>
            <div className="my-6 md:my-2 group md:max-w-sm xl:max-w-md cursor-pointer rounded-lg dark:hover:bg-gray-800 hover-border-transparent bg-gray-50 dark:bg-gray-900 shadow-sm hover:bg-gray-100 flex flex-row flex-1 mx-4 font-semibold text-base justify-center items-center">
                <div className="w-32 h-32 object-cover transition-all p-3 pl-3">
                    <img
                        className="w-full h-full transition-all rounded-md object-cover"
                        alt={props.title}
                        src={props.thumbnail}
                    />
                </div>
                <div className="py-3 px-6 pl-2 flex-1">
                    <div className="mb-2">{props.title}</div>
                    <div className="text-sm text-gray-500 dark:text-gray-400 font-normal">
                        {props.description}
                    </div>
                    {props.date && (
                        <div className="text-xs text-gray-400 mt-2 font-normal">
                            {shortDate(props.date)}
                        </div>
                    )}
                </div>
            </div>
        </Link>
    );
}